<template>
  <v-container class="h-100 dashboard">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
          <Loader />
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col cols="12">
        <v-card class="pt-8 pb-8 pl-8 pr-8 text-center">
          <div class="text-error"><v-icon style="font-size:5em;margin-top:8px;">mdi-alert</v-icon> </div>
          <div class="text-error">{{ this.errorMessage }}</div>
          <div class="fake-link mt-3" @click="this.error=false;this.loading=true;this.loadDashboard()">Ressayer</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row style="height:70px;position:relative;margin-top:5px;">
      <div class="pl-4 pt-4 page-title flex-grow-1 d-flex justify-start h-100" style="align-items: center">
        Projets
      </div>
      <div class="pr-3">
        <v-text-field class="search-input" v-model="search" :append-inner-icon="'mdi-magnify'" placeholder="Nom du projet"></v-text-field>
      </div>
    </v-row>
    <v-row>
      <template v-for="item, key in projects" :style="'z-index:' + (999 - key)">
        <v-col class="position-relative v-col-lg-4 v-col-md-6 v-col-xl-3 v-col-xxl-2"  v-if="item['name'].toLowerCase().includes(search.toLowerCase())">
          <v-card class="h-100 card-action overflow-hidden p-0" style="box-shadow: 0px 0px 3px rgba(225,226,228,0.63)">
            <div class="d-flex h-100">
              <div class="flex-grow-1 d-flex flex-column h-100">
                <div class="top-card">
                  <div :style="{'background' : item['status']['other'] === 0 || !item['isKube'] ? '#30a76d' : '#b53351'}" style="width:5px;height:80px;position:absolute;left:0;top:0;"></div>
                  <div class="flex-grow-1">
                    <div class="top-card-name">{{ item['name'] }}</div>
                    <div class="top-card-cluster"> {{ item['cluster']['id'] !== null ? item['cluster']['name'] : '-' }}</div>
                  </div>
                  <div>
                    <div class="d-flex pt-2 pb-2" style="font-size:0.85em;" v-if="item['isKube']">
                      <div class="ml-2" v-for="namespace in item['namespaces']">
                        <router-link :to="'/namespace/' + item['cluster']['id'] +  '/' + namespace"><div class="namespace-chips"><v-icon style="font-size:1.7em;color:#548bff">mdi-kubernetes</v-icon> {{ namespace }}</div></router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-stat d-flex flex-grow-1">
                  <div class="flex-grow-1">
                    <div class="d-flex">
                      <div style="font-size:1.2em;font-weight:600;flex:1;" v-if="item['isKube']">Pods ({{ item['status']['running'] + item['status']['other'] }})</div>
                      <div style="font-size:1.2em;font-weight:600;" v-if="!item['isKube']">Aucun pods</div>

                      <div class="justify-center align-center d-flex">
                        <div v-if="item['isKube']" class="ml-2">
                          <div style="color:#999;font-size:0.9em;text-align:right;border-radius:10px;">
                            <v-icon style="display:inline-block;margin-top:-2px;" >mdi-memory</v-icon>
                            {{ item['memory'].toFixed(0) }} Mo
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="font-size:1em;padding:15px 0 5px 0;">
                      <div v-if="item['status']['other'] === 0 || !item['isKube']">
                        <v-icon class="text-success mr-2">mdi-check-circle-outline</v-icon> Aucun problème kubernetes
                      </div>
                      <div v-else>
                        <v-icon class="text-warn mr-2">mdi-alert-outline</v-icon> Disfonctionnements kubernetes
                      </div>
                    </div>
                    <div v-if="item['errors'] && item['errors'].length > 0" style="font-size:1em;padding:15px 0 5px 0;width:100%;">
                      <div style="background:#fde0e3;padding:5px;border-radius:5px;margin-top:8px;text-transform: uppercase;display:flex;font-size:0.8em;" v-for="error in  item['errors']">
                        <div style="width:10px;height:10px;background:#ab3548;border-radius:50%;margin-top:4px;margin-right:7px;margin-left:7px;"></div>
                        <div style="color:#000000de;flex:1;padding-top:2px;">{{ error }}</div>
                        <div style="font-size:1.1em;"><v-icon>mdi-chevron-right</v-icon></div>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="pt-4 d-flex pl-4 mb-3">
                  <StatCard
                      :icon="gitlabIcon"
                      title="Merge Request"
                      :value="(item['gitlab'] !== undefined) ? item['gitlab']['mr'] : 0"
                  />
                  <StatCard
                      :icon="youtrackIcon"
                      title="ticket Youtrack"
                      :value="(item['youtrack'] !== undefined) ? item['youtrack']['tickets'] : 0"
                  />
                </div>


              </div>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import Loader from "@/components/ui/Loader.vue";
import StatCard from "@/components/ui/StatCard.vue";
import youtrackIcon from '@/assets/youtrack.png'
import gitlabIcon from '@/assets/gitlab.png'

import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

export default {
  name: 'DashboardPage',
  components: {StatCard, Loader, GuestCard, BackButton, VueTree, youtrackIcon, gitlabIcon },
  data() {
    return {
      search: '',
      loading: false,
      error: false,
      errorMessage: null,
      ressources: [],
      namespaces: [],
      clusters: [],
      projects: [],
      activeCluster: null,
      ramMax: 0,
      sync: null,
      gitlabIcon: gitlabIcon,
      youtrackIcon: youtrackIcon
    }
  },
  unmounted() {
    clearInterval(this.sync);
  },
  methods: {
    async loadDashboard() {
      try {
        clearInterval(this.sync);
        let clusterUrl = this.activeCluster !== null ? this.activeCluster['id'] : 0;
        await DashboardService.loadDashboard(clusterUrl).then(response => {
          this.ramMax = 0;
          this.error = false;
          this.sync = setTimeout(() => {this.loadDashboard()}, 3000);
          this.projects = response['projects'];
        })
      } catch (error) {
        handleResponseError(error, this.$t)
        this.error = true;
        this.errorMessage = 'Une erreur est survenue';
      } finally {
        this.loading = false
      }
    },
    selectCluster(item) {
      this.activeCluster = item;
      this.loadDashboard();
    }
  },
  mounted() {
    this.loading = true
    this.loadDashboard();
  }

}
</script>


<style scoped>
@keyframes center-animate {
  0% {
    background:#51a175
  }
  50% {
    background: #199350;
  }
  100% {
    background:#51a175;
  }
}

 .bar-bg {
   background:#ccc;
   position:relative;
   height:15px;
   border-radius:5px;
   overflow:hidden;

   &:hover {
     .bar-line {
       animation: center-animate 2s ease infinite alternate;
     }
   }
 }

 .bar-line {
   width:0;
   height:15px;
   background:#51a175;
   animation-delay: 2s;
   position:relative;
 }

 .bar-line-danger {
   background:orange!important;
 }
</style>
