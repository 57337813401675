<template>
  <v-container class="h-100">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        <v-icon>mdi-chevron-right</v-icon> Demande de télétravail > Ajouter
      </div>
      <div>

      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <div class="d-flex">
            <v-form class="w-100" @submit.prevent="sendForm">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="form-group">
                    <div class="form-label">Date</div>
                    <div class="form-input">
                      <v-date-picker
                          :min="new Date().toISOString().substr(0, 10)"
                          v-model="variableKey"
                          type="text"
                          required
                          autofocus
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-2">
                <v-col cols="12" class="text-center">
                  <v-btn
                      text="Modifier"
                      :loading="loading"
                      :disabled="variableKey === null || variableKey === undefined"
                      type="submit"
                  />
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import {fr} from "date-fns/locale";
import moment from 'moment-timezone';
import "moment/locale/fr";
import ProjectsService from "@/services/ProjectsService.js";
import Loader from "@/components/ui/Loader.vue";
import JobService from '@/services/JobService.js';

moment.locale('fr');

export default {
  name: 'HomeOfficeAddPage',
  computed: {},
  components: {Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      variableKey: null,
      variable: [],
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
  },
  methods: {
    async loadProjects() {
      this.loading = true
    },
    async sendForm() {
      JobService.homeOfficeRequestAdd({date : this.variableKey}).then(response => {
        if(response['success']) {
          this.$router.push({ name: 'homeOfficePage'});
        } else {
          alert('erreur de mise a jour');
        }
      });
    }
  },
  mounted() {}

}
</script>
