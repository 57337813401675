<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        Administration <v-icon>mdi-chevron-right</v-icon> Utilisateurs
      </div>
      <div>
        <router-link :to="'/users/add'">
          <v-btn class="bg-primary btn"><v-icon>mdi-plus</v-icon> Ajouter</v-btn>
        </router-link>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="p-0">
          <div class="d-flex">
            <v-table class="w-100 custom-table">
              <thead class="text-left">
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>Prenom</th>
                <th>Nom</th>
                <th width="50">Action</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="user in users" >
                <td class="text-left">{{ user['id'] }}</td>
                <td class="text-left">{{ user['username'] }}</td>
                <td class="text-left">{{ user['firstname'] }}</td>
                <td class="text-left">{{ user['lastname'] }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="custom-vlist">
                      <v-list-item class="pl-0 pr-0">
                        <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions</v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="editUser(user['id'])"><div class="d-block flex-grow-1 pr-4">Editer</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="deleteUser(user['id'])"><div class="d-block flex-grow-1 pr-4">Supprimer</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import Loader from "@/components/ui/Loader.vue";
moment.locale('fr');

export default {
  name: 'UsersPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: {Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      users: [],
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadUser() {
      this.loading = true
      try {
        await UsersService.usersList().then(response => {
          this.users = response['users'];
        })
        useSnackbarStore().openSnackbar({
          text: this.$t('FORGOT_PASSWORD_PAGE.SUCCESS'),
          color: 'success'
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async deleteUser(id) {
      await UsersService.deleteUser(id).then(response => {
        this.loadUser();
      })
    },
    editUser(id) {
      this.$router.push({ name: 'UsersEdit', params: {id : id} });
    },
    formatDate(dateString) {
      return this.moment(dateString).fromNow();
    }
  },
  mounted() {
    this.loading = true;
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loadUser();
  }

}
</script>

<style scoped>

</style>
