<template>
  <v-container class="pt-5">
    <v-row>
      <v-col class="" cols="6">
        <v-col col="12">
          <v-menu v-if="selectedPod !== null">
            <template v-slot:activator="{ props }">
              <v-card v-bind="props">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <div class="d-flex">
                      <div class="pr-3 pt-2">
                        <div
                            v-if="(selectedPod['status']['phase'] == 'Running' || selectedPod['status']['phase'] == 'Succeeded') && searchPods(selectedPod)"
                            style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                        <div class="bg-error"
                             v-if="(selectedPod['status']['phase'] != 'Running' && selectedPod['status']['phase'] != 'Succeeded') || !searchPods(selectedPod)"
                             style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                      </div>
                      <div>
                        <div style="font-size:0.9em;color:#575757;">{{ selectedPod['metadata']['name'] }}</div>
                        <div v-if="searchPods(selectedPod)"  style="font-size:0.8em;color:#aaa;margin-top:-5px;">
                          {{ formatDate(selectedPod['metadata']['creationTimestamp']) }}
                        </div>
                        <div v-if="!searchPods(selectedPod)" class="text-danger" style="font-size:0.8em;color:#aaa;margin-top:0px;">
                          Ce pod n'existe plus, veuillez sélectionner un pod dans la liste
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon>
                  </div>
                </div>
              </v-card>
            </template>
            <v-list>
              <v-list-item v-for="item in ressources" class="pl-0 pr-0 pt-0 pb-0"
                           @click="loadLogs(item['metadata']['name'], item, 'manual')">
                <v-list-item-title class="item-list">
                  <div class="d-flex">
                    <div class="pr-3 pt-2">
                      <div v-if="item['status']['phase'] == 'Running' || item['status']['phase'] == 'Succeeded'"
                           style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                      <div class="bg-error"
                           v-if="item['status']['phase'] != 'Running' && item['status']['phase'] != 'Succeeded'"
                           style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                    </div>
                    <div>
                      <div style="font-size:0.8em;color:#575757;">{{ item['metadata']['name'] }}</div>
                      <div style="font-size:0.7em;color:#aaa;margin-top:-8px;">
                        {{ formatDate(item['metadata']['creationTimestamp']) }}
                      </div>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col cols="12" v-if="selectedPod !== null">
          <v-card class="pl-0 pt-0 pr-0 pb-4">
            <div class="">
              <div class="d-flex top-card pa-4">
                <div>
                  <div class="top-card-title font-weight-bold">Information du pod</div>
                  <div class="top-card-subtitle"> {{ selectedPod['metadata']['name'] }}</div>
                </div>
              </div>
              <div class="mt-3" style="margin-left:20px;font-size:0.95em;">
                <v-row>
                  <v-col cols="6">
                    <div class="top-card-title font-weight-bold">Cluster</div>
                    <div class="top-card-data">{{ cluster['name'] }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="top-card-title font-weight-bold">Namespace</div>
                    <div class="top-card-data">{{ selectedPod['metadata']['namespace'] }}</div>
                  </v-col>
                  <div class="card-sep"></div>

                  <v-col cols="6">
                    <div class="top-card-title font-weight-bold">Noeud</div>
                    <div class="top-card-data">{{ selectedPod['spec']['nodeName'] }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div class="top-card-title font-weight-bold">Nom</div>
                    <div class="top-card-data">{{ selectedPod['metadata']['name'] }}</div>
                  </v-col>
                  <div class="card-sep"></div>

                  <v-col cols="12">
                    <div class="top-card-title font-weight-bold">Image</div>
                    <div class="top-card-data">{{ selectedPod['spec']['containers'][0]['image'] }}</div>
                  </v-col>
                  <div class="card-sep"></div>

                  <v-col cols="12">
                    <div class="top-card-title font-weight-bold">Arguments</div>
                    <div class="top-card-data">{{
                        (selectedPod['spec']['containers'][0] !== undefined && selectedPod['spec']['containers'][0]['args'] !== undefined && selectedPod['spec']['containers'][0]['args'][0] !== undefined) ? selectedPod['spec']['containers'][0]['args'][0] : '-'
                      }}
                    </div>
                  </v-col>

                </v-row>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="selectedPod !== null && selectedPod['spec']['containers'][0]['env'] !== undefined &&  selectedPod['spec']['containers'][0]['env'].length > 0">
          <v-card class="p-0">
            <div class="">
              <div class="d-flex top-card">
                <div>
                  <div class="top-card-title font-weight-bold">Environnements</div>
                  <div class="top-card-subtitle">Variables d'environnements appliquer au conteneurs actifs</div>
                </div>
              </div>
              <div class="mt-3" style="margin-left:20px;font-size:0.85em;max-height:500px!important;overflow-y:scroll;overflow-x:hidden;">
                <v-row>
                  <template v-for="env in selectedPod['spec']['containers'][0]['env']">
                    <v-col cols="12">
                      <div class="top-card-title font-weight-bold">{{ env['name'] }}</div>
                      <div class="top-card-data">{{ env['value'] ?? '-' }}</div>
                    </v-col>
                    <div class="card-sep"></div>
                  </template>

                </v-row>
              </div>
            </div>
          </v-card>
        </v-col>

      </v-col>
      <v-col cols="6">

        <v-card class="mt-3 mb-6 d-flex pt-0 pb-0 pl-0" v-if="selectedPod !== null">
          <div class="d-flex flex-grow-1" v-if="module === 'log'">
            <div class="pt-0 pl-3 pt-5 pb-5 item-link bg-primary text-white" style="padding:15px;" @click="deletePods(selectedPod['metadata']['name'])">
              <v-icon style="font-size:1.5em;" class="pr-3 pl-2">mdi-restart</v-icon>
              <span v-if="!loading">Redémarrer</span> <span v-if="loading">Patientez ...</span>
            </div>

            <div v-if="this.selectedPod['metadata']['name'].includes('mysql')" class="pt-5 item-link ml-5 text-primary"
                 @click="mysql(selectedPod['metadata']['name'])">
              <v-icon style="font-size:1.5em;">mdi-database</v-icon>
              Accéder à la base de données
            </div>
          </div>
          <div class="d-flex flex-grow-1" v-if="module === 'mysql'">
            <div class="pt-5 pb-5 pl-3 item-link text-primary" @click="module = 'log'" v-if="selectedTable === null">
              <v-icon style="font-size:1.5em;">mdi-arrow-left</v-icon>
              <span v-if="!loading">Retour aux logs</span> <span v-if="loading">Patientez ...</span>
            </div>
            <div class="pt-5 pb-5 pl-3 item-link text-primary" @click="mysqlReturnTable()" v-if="selectedTable !== null">
              <v-icon style="font-size:1.5em;">mdi-arrow-left</v-icon>
              <span v-if="!loading">Retour aux tables</span> <span v-if="loading">Patientez ...</span>
            </div>
            <div class="pt-5 pl-4" v-if="selectedTable !== null">
              <v-icon style="font-size:1.5em;">mdi-table</v-icon>
              {{ selectedTable }}
            </div>

            <div class="pl-4 item-link" style="padding-top:7px;color:#aaa;font-size:0.8em;" @click="editSql = true"
                 v-if="module === 'mysql' && selectedPod !== null && selectedTable !== null">
              <v-icon style="font-size:1em;">mdi-pencil</v-icon>
              éditer la requête
            </div>

          </div>
          <div class="pt-5">
            <v-icon style="font-size:1.5em;">mdi-clock-outline</v-icon>
            {{ dateSync }}
          </div>
        </v-card>
        <v-card v-if="selectedPod === null" class="pt-4 pr-4 pl-4 pb-4 mt-3 text-center">
          Veuillez sélectionner un pods
        </v-card>
        <v-card v-if="module === 'log' && selectedPod !== null" class="mt-3 pl-0 pr-0 pt-0"  ref="scrollContainer">

          <div class="d-flex top-card mb-3">
            <div>
              <div class="top-card-title">Logs du pod</div>
              <div class="top-card-subtitle"> {{ selectedPod['metadata']['name'] }}</div>
            </div>
          </div>

          <div v-if="logs !== undefined && logs.length === 0 && !loadingLog" class="pl-4 pr-4 pt-4 pb-4 text-center w-100">
            Aucun log pour <b class="text-primary">{{ selectedPod['metadata']['name'] }}</b>
          </div>
          <div v-else-if="loadingLog" class="pl-4 pr-4 pt-4 pb-4 text-center w-100">
            Chargement des logs pour <b class="text-primary">{{ selectedPod['metadata']['name'] }}</b>
            <loader></loader>
          </div>
          <div v-else style="overflow-y:scroll;max-height:75vh">
            <div v-for="(log, logindex) in logs" class="d-flex pl-3 pr-3">
              <div style="color:#999;min-width:140px;font-size:0.9em;"
                   v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id']) && log['date'] !== null">
                {{
                  log['date'] !== null ? moment(log['date']['date']).tz('Europe/Paris').format('DD/MM/YYYY HH:mm:ss') : ''
                }}
              </div>
              <div style="color:#25a125;font-size:0.9em;"
                   v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id'])">
                {{ log['content'] }}
              </div>
              <div class="other-pod"
                  v-if="logs[logindex + 1] !== undefined && (log['container_id'] !== logs[logindex + 1]['container_id'])">
                CHANGEMENT DE POD : {{ logs[logindex + 1]['container_id'] }}
              </div>
            </div>
          </div>
        </v-card>
        <v-card v-if="module === 'mysql' && selectedPod !== null && selectedTable !== null && editSql"
                class="d-flex w-100" style="max-height:75px;">
          <div class="flex-grow-1">
            <v-text-field v-model="query"/>
          </div>
          <div class="ml-3">
            <v-btn class="btn btn-primary"
                   @click="queryMysql(this.selectedPod['metadata']['name'], this.selectedTable, this.query)">Executer
            </v-btn>
          </div>
        </v-card>
        <v-card v-if="module === 'mysql' && selectedPod !== null" class="mt-3">
          <div class="text-center pt-4 pb-4"
               v-if="mysqlTable.length === 0 || (selectedTable !== null && mysqlValues.length === 0)">
            Chargement des données ...
          </div>

          <div v-if="mysqlCols.length > 0">
            <v-table class="w-100 v-table">
              <thead>
              <tr>
                <th v-for="col in mysqlCols">{{ col['name'] }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in mysqlValues">
                <td v-for="value in row">{{ value }}</td>
              </tr>
              </tbody>
            </v-table>
          </div>

          <div v-if="mysqlCols.length === 0 && selectedTable === null">
            <div v-for="table in mysqlTable">
              <div class="item-link text-primary cursor-pointer pt-2 pb-2" style="border-bottom:solid 1px #eee;"
                   @click="queryMysql(selectedPod['metadata']['name'], table[0])">
                <v-icon style="font-size:1.5em;">mdi-table</v-icon>
                {{ table[0] }}
              </div>
            </div>
          </div>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackButton from '@/components/ui/BackButton.vue'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from '@/services/DashboardService.js';
import { fr } from 'date-fns/locale';
import moment from 'moment-timezone';
import 'moment/locale/fr';

import VueTree from '@ssthouse/vue3-tree-chart';
import '@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css';
import axios from 'axios';
import Loader from '@/components/ui/Loader.vue';
import { nextTick } from 'vue';

moment.locale('fr');

export default {
  name: 'NamespacePage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { Loader, GuestCard, BackButton, VueTree },
  data() {
    return {
      cancelTokenSource: axios.CancelToken.source(),
      loading: false,
      loadingLog: false,
      ressources: [],
      namespaces: [],
      ramMax: 0,
      date: new Date(),
      logs: [],
      moment: moment,
      sync: null,
      syncPods: null,
      selectedPod: null,
      mysqlTable: [],
      mysqlCols: [],
      mysqlValues: [],
      mysqlPort: null,
      selectedTable: null,
      module: 'log',
      query: null,
      editSql: false,
      cluster: null,
      dateSync: new Date(),
      sampleData: {
        value: 'batimat-dev',
        children: [
          { value: 'ingress', children: [{ value: '4' }, { value: '5' }] },
          { value: 'pods' },
          { value: 'mysql' },
          { value: 'mailhog' }
        ]
      },
      treeConfig: { nodeWidth: 80, nodeHeight: 10, levelHeight: 300 }
    }
  },
  unmounted() {
    console.log('namespace unmount')
    clearInterval(this.syncPods);
    clearInterval(this.sync);
    this.cancelTokenSource.cancel('Request canceled by the user.');
  },
  methods: {
    async loadDashboard(first) {
      clearInterval(this.syncPods);

      try {
        await DashboardService.loadNamespace(this.$route.params.cluster, this.$route.params.id).then(response => {
          this.ressources = response['pods'];
          this.sampleData = response['three'];
          this.cluster = response['cluster'];

          if (first) {
            this.selectedPod = this.ressources[0];
            this.loadLogs(this.ressources[0]['metadata']['name'], this.ressources[0])
          }
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.syncPods = setTimeout(() => {this.loadDashboard(false)}, 3000);
      }
    },
    async deletePods(pod) {
      this.loading = true;
      await DashboardService.deletePods(this.$route.params.cluster, this.$route.params.id, pod).then(response => {
        this.loading = false;
        this.module = 'log';
        this.logs = [];
        clearInterval(this.sync);
        this.cancelTokenSource.cancel('Request canceled by the user.');
        this.cancelTokenSource = axios.CancelToken.source();
      });
    },
    searchPods(pod) {
      let search = false;

      this.ressources.forEach(item => {
        if(item['metadata']['name'] === pod['metadata']['name']) {
          search = true;
        }
      })

      return search;
    },
    async loadLogs(pod, item, type = 'auto') {
      clearInterval(this.sync);

      if(type === 'manual') {
        this.selectedPod = item;
        this.cancelTokenSource.cancel('Request canceled by the user.');
        this.cancelTokenSource = axios.CancelToken.source();
        this.loadingLog = true;
        this.logs = [];
      }

      try {
        await DashboardService.loadLog(this.$route.params.cluster, this.$route.params.id, item['metadata']['name'], this.cancelTokenSource, item['metadata']['labels']['app']).then(response => {
          if(response['logs'] !== undefined) {
            this.logs = response['logs']
            this.loadingLog = false;
            this.dateSync = moment(response['synchro']).tz('Europe/Paris').format('HH:mm:ss')
          }
        });
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.sync = setTimeout(() => {this.loadLogs(pod, item)}, 1000);
      }

    },
    async mysql(pod) {
      this.module = 'mysql';
      this.mysqlTable = [];

      DashboardService.redirectPod(this.cluster['id'], this.$route.params.id, pod, this.mysqlPort).then(response => {
        this.mysqlTable = response['tables']
        this.mysqlPort = response['port']
      })
    },
    async queryMysql(pod, table, query = null) {
      this.selectedTable = table;
      this.query = (query === null) ? 'select * from ' + table + ' LIMIT 0,100' : query;

      DashboardService.mysql(this.$route.params.id, this.$route.params.id, pod, this.mysqlPort, this.query).then(response => {
        this.mysqlCols = response['cols']
        this.mysqlValues = response['tables']

        if (query !== null) {
          this.editSql = false;
        }
      })
    },
    mysqlReturnTable() {
      this.selectedTable = null;
      this.mysqlCols = [];
      this.mysqlValues = [];
      DashboardService.redirectPod(this.cluster['id'], this.$route.params.id, this.selectedPod['metadata']['name'], this.mysqlPort).then(response => {
        this.mysqlTable = response['tables']
        this.mysqlPort = response['port']
      })
    },
    formatDate(dateString) {
      return this.moment(dateString).fromNow();
    }
  },
  mounted() {
    this.loadDashboard(true);
  }

}
</script>


<style scoped>
.bar-bg {
  background: #1a1b1e;
  position: relative;
  height: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.bar-line {
  width: 20%;
  height: 25px;
  background: #2a4f2a;
}

.bar-line-danger {
  background: #916022 !important;
}
</style>
