<script>
import { useTheme } from 'vuetify'
import { useThemeStore } from '@/stores/ThemeStore.js';

export default {
  name: 'GuestLayout',
  methods: { useThemeStore },
  data(){
    return {
      theme: useTheme().global.name,
    }
  },
  computed: {

  }
}
</script>

<template>
  <v-app :class="{'theme-light' : !useThemeStore().black, 'theme-dark' : useThemeStore().black}">
    <v-main>
        <slot/>
    </v-main>
  </v-app>
</template>

<style scoped>
  .v-application {
    background-color: #F7F9FB;
  }
  .v-btn{
    text-transform: unset;
  }
</style>
