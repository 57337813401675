import { defineStore } from 'pinia'

export const useThemeStore = defineStore('theme', {
  state: () => ({
    black: window.localStorage.getItem('theme') === 'true',
  }),
  actions: {
    switch(){
      this.black = !this.black;
      window.localStorage.setItem('theme', this.black);
    }
  }
})
