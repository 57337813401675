<template>
  <v-container class="h-100 pt-4">

    <div v-if="showIssue" style="position:fixed;z-index:9999;background:black;width:100%;height:100%;opacity:0.9;margin:-25px 0 0 -25px;"></div>

    <div v-if="showIssue" style="position:fixed;z-index:10000;width:100%;height:100%;display:flex;justify-content: end;align-items: end;margin-top:-25px;">
      <v-card class="fadeLeft" style="width:70%;max-width:700px;min-height:100%;margin-top:-60px;padding-right:125px;">
        <div class="side-close" @click="showIssue = false"><v-icon>mdi-close</v-icon></div>
        <div style="font-size:1.3em;font-weight:500;">{{ this.detailIssue['title'] }}</div>
        <div style="font-size:0.9em;font-weight:500;color:#999;">{{ this.detailIssue['id'] }}</div>
        <div style="margin-top:10px;max-height:400px;overflow-y:scroll;padding:10px;border-radius:5px;font-size:0.9em;;background:#fafafa;" v-html="this.detailIssue['description'] === '' ? 'Pas de description' : this.detailIssue['description']"></div>
        <v-row style="background:#f1f4ff;border-radius:10px;padding:15px;margin:0px;margin-top:25px;">
          <v-col col="6" class="d-flex justify-center align-content-center">
            <StatCard
                style="margin: 0 auto;"
                icon="mdi-clock"
                title="Estimation"
                :center="true"
                :value="(this.detailIssue['estimate'] === null) ? '-' : calcTime(this.detailIssue['estimate'], true)"
            />
          </v-col>
          <v-col col="6" class="d-flex justify-center align-content-center">
            <StatCard
                icon="mdi-account-hard-hat"
                title="Temps passé"
                :center="true"
                :value=" calcTime(this.detailIssue['time'], true)"
            />
          </v-col>
          <v-col col="6" class="d-flex justify-center align-content-center">
            <StatCard
                icon="mdi-account-hard-hat"
                title="Restant"
                :center="true"
                :value="calcTime(this.detailIssue['estimate'] - this.detailIssue['time'], true)"
            />
          </v-col>
        </v-row>
        <div>
          <v-table class="custom-table w-100">
            <thead>
              <tr>
                <th>Collaborateur</th>
                <th>Durée</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="time in detailIssue['timeJson']">
              <td>{{ time['user'] }}</td>
              <td>{{ time['duration'] }} minutes</td>
              <td>{{ time['date'] !== null ? time['date']['date'].split(' ')[0] : '-' }}</td>
            </tr>
            </tbody>
          </v-table>

        </div>
      </v-card>
    </div>

    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader/>
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title d-flex">
        <div>

        </div>
        <div>
          {{ this.selectedProject !== null ? 'Statistiques ' + this.selectedProject['name'] : 'Statistiques' }}
          <div v-if="this.selectedProject !== null && this.selectedProject['tags'] !== null" style="font-size:0.6em;">
            Recette : {{ this.selectedProject !== null ? this.selectedProject['tags'] : '' }}
          </div>
        </div>
      </div>
      <div>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-card v-bind="props">
              <div class="d-flex" style="width:200px;">
                <div class="flex-grow-1">
                  <div class="d-flex">
                    <div class="pr-3 pt-2">
                    </div>
                    <div>
                      <div style="font-size:0.9em;color:#575757;">
                        {{ this.selectedProject !== null ? this.selectedProject['name'] : 'chargement ...' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-card>
          </template>
          <v-list>
            <v-list-item v-for="item in projectsList" class="pl-0 pr-0 pt-0 pb-0">
              <v-list-item-title class="item-list" @click="selectProject(item)">
                <div class="d-flex">
                  <div>
                    <div style="font-size:0.8em;color:#575757;">{{ item['name'] }}</div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-row>
          <v-col col="12">
            <v-card class="overflow-visible position-relative fadeUp" style="z-index:99;">
              <v-row>
                <v-col col="3">
                  <div class="stat-info-card">
                    <StatCard
                        icon="mdi-clock"
                        title="Durée moyenne d'une merge request"
                        :value="projects !== undefined && projects !== null && projects['stats'] !== undefined ? calcTime(projects['stats']['time']) : 'Inconnu'"
                    />
                    <div class="info d-flex">
                      <div class="mr-3">
                        <v-icon>mdi-information-outline</v-icon>
                      </div>
                      <div>
                        C'est la durée moyenne entre la création et le merge de l'ensemble des merges request du projet calculé sur une base de 24h sans prendre en compte les week-end et jour fériés
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col col="3">
                  <div class="stat-info-card">
                    <StatCard
                        icon="mdi-merge"
                        title="Ratio estimation/temps passé"
                        :value="projects !== undefined && projects !== null && projects['stats'] !== undefined ? (projects['stats']['issues']['pastTime'] / projects['stats']['issues']['estimate']).toFixed(2) : 'Inconnu'"
                    ></StatCard>
                    <div class="info d-flex">
                      <div class="mr-3">
                        <v-icon>mdi-information-outline</v-icon>
                      </div>
                      <div>
                        Un ratio inférieur à 1.0 signifie que les tickets sont traités plus rapidement que leur temps
                        d'estimation
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col col="3">
                  <div class="stat-info-card">
                    <StatCard
                        icon="mdi-merge"
                        title="Temps passé"
                        :value="projects !== undefined && projects !== null && projects['youtrack'] !== undefined && projects['estimatedProject'] > 0 ? ((projects['youtrack']['totalDuration'] / 60) / 7).toFixed(0) + ' J/H (' + (projects['youtrack']['totalDuration'] / projects['estimatedProject'] * 100).toFixed(0) + '  %)' : 'Inconnu'"
                    ></StatCard>
                    <div class="info d-flex">
                      <div class="mr-3">
                        <v-icon>mdi-information-outline</v-icon>
                      </div>
                      <div>
                        Temps passé calculé en temps réel sur la base du temps renseigné sur les tickets Youtrack sur une base de 7H par jour
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col col="3">
                  <div class="stat-info-card">
                    <StatCard
                        icon="mdi-alert"
                        title="Temps restant"
                        :value="projects !== undefined && projects !== null && projects['times'] !== undefined ? calcTime(projects['times']['notstaffed'], true, true) : 'Inconnu'"
                    />
                    <div class="info d-flex">
                      <div class="mr-3">
                        <v-icon>mdi-information-outline</v-icon>
                      </div>
                      <div>
                        Le nombre de jours de travail restant sur la base des ticket estimé présents dans Youtrack. La date de fin supposé ne prend pas en compte les weeks-end
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col col="6" class="flex-grow-1 overflow-hidden">
            <v-card class="h-100 d-flex justify-center align-content-center flex-column pl-4 pr-4 fadeUp"
                    v-if="this.chartMr.length === 0">
              <div class="text-center mb-4">
                <v-icon class="text-error" style="font-size:4em;">mdi-alert</v-icon>
              </div>
              <div class="text-error text-center" style="font-size:0.9em;">Impossible d'afficher le graphique
                Aucune merge request trouvée
              </div>
            </v-card>
            <v-card v-if="this.chartMr.length > 0" class="fadeUp">
              <div class="mb-3" style="font-size:1.1em;font-weight:500;">Merge request journalière</div>
              <ChartBar :values="this.chartMr"/>
            </v-card>
          </v-col>
          <v-col col="6" class="flex-grow-1">
            <v-card class="h-100 fadeUp"
                    v-if="projects !== undefined && projects !== null && projects['youtrack'] !== undefined && this.projects['estimatedProject'] !== null && this.projects['estimatedProject'] !== 0">
              <div class="mb-3" style="font-size:1.1em;font-weight:500;">Avancé du projet</div>
              <div class="d-flex" style="border-radius: 5px;overflow: hidden">
                <div style="height:40px;width:20%;background:#51a175;"
                     :style="'width:' + totalPastTime().toFixed(0) + '%'"></div>
                <div style="height:40px;width:40%;background:#2196F3;"
                     :style="'width:' + totalFreeTime().toFixed(0) + '%'"
                     v-if="projects['youtrack']['totalDuration'] < projects['estimatedProject']"></div>
                <div style="height:40px;width:40%;background:#c94848;"
                     :style="'width:' + totalOvertime().toFixed(0) + '%'"></div>
              </div>
              <div class="mt-5">
                <div class="d-flex mb-3">
                  <div
                      style="border-radius:4px;width:10px;height:10px;background:#51a175;margin-top:6px;margin-right:7px;"></div>
                  <div>{{ (totalOvertime() > 0) ? 100 : totalPastTime().toFixed(0) }}% du temps effectué</div>
                </div>
                <div class="d-flex mb-3">
                  <div
                      style="border-radius:4px;width:10px;height:10px;background:#2196F3;margin-top:6px;margin-right:7px;"></div>
                  <div>{{ totalFreeTime().toFixed(0) }}% du temps restant</div>
                </div>
                <div class="d-flex mb-3">
                  <div
                      style="border-radius:4px;width:10px;height:10px;background:#c94848;margin-top:6px;margin-right:7px;"></div>
                  <div>{{ totalOvertime().toFixed(0) * 2 }}% du temps hors délais</div>
                </div>
              </div>
            </v-card>
            <v-card class="h-100 d-flex justify-center align-content-center flex-column pl-4 pr-4 fadeUp"
                    v-if="projects !== undefined && projects !== null && (projects['estimatedProject'] === null || projects['estimatedProject'] === 0)">
              <div class="text-center mb-4">
                <v-icon class="text-error" style="font-size:4em;">mdi-alert</v-icon>
              </div>
              <div class="text-error text-center" style="font-size:0.9em;">Impossible de calculer un avancement car le
                nombre de jours homme estimé pour le projet n'est pas renseigné
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col col="6">
              <v-row class="mb-6">
                <v-col cols="12" class="d-flex mb-0 pb-0">
                  <div class="flex-grow-1 d-flex pt-3">
                    <v-icon style="font-size:1.8em;margin-top:8px;">mdi-jira</v-icon>
                    <div class="pt-2 pl-2" style="font-size:1.2em;font-weight:bold;">Youtrack</div>
                  </div>
                  <div>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-card v-bind="props">
                          <div class="d-flex" style="width:200px;">
                            <div class="flex-grow-1">
                              <div class="d-flex">
                                <div class="pr-3 pt-2">
                                </div>
                                <div>
                                  <div style="font-size:0.9em;color:#575757;">
                                    {{ this.youtrackStat === 1 ? 'Tous' : 'En dépassement' }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon>
                            </div>
                          </div>
                        </v-card>
                      </template>
                      <v-list>
                        <v-list-item v-for="item in [{id: 1, name : 'Tous'}, {id: 2, name : 'En dépassement'}]" class="pl-0 pr-0 pt-0 pb-0">
                          <v-list-item-title class="item-list" @click="selectYoutrackStat(item)">
                            <div class="d-flex">
                              <div>
                                <div style="font-size:0.8em;color:#575757;">{{ item['name'] }}</div>
                              </div>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
                <v-col col="12">
                  <div class="mb-0 merge-request-item" @click="openIssue(mergeRequest)" v-for="mergeRequest in projects['issues']" style="padding:0 0 10px 0;">
                    <v-card class="fadeUp ml-0 mr-0" style="min-height:93px;">
                    <div class="d-flex">
                      <div class="flex-grow-1 d-flex">
                        <div class="mr-3">
                          <div class="stat-card-icon"
                               :class="{'stat-card-success': mergeRequest['state'] === 'merged'}">
                            <v-icon :class="{'text-success': mergeRequest['status'] === 'Terminé'}">
                              {{ mergeRequest['status'] === 'Terminé' ? 'mdi-check' : 'mdi-clock' }}
                            </v-icon>
                          </div>
                        </div>
                        <div class="d-flex w-100">
                          <div class="flex-grow-1">
                            <div class="d-flex">
                              <div style="font-weight:bold;">{{ mergeRequest['title'] }}</div>
                              <div class="ml-2" style="margin-top:-5px;"></div>
                            </div>

                            <div style="color:#959595;font-size:0.85em;">{{ mergeRequest['id'] }}</div>
                            <div style="font-size:0.9em;color:#999;padding-top:8px;padding-bottom:0;margin-bottom:-10px;">
                              <v-tooltip text="Temps passé" location="bottom">
                                <template v-slot:activator="{ props }">
                                  <span v-bind="props">  <v-icon>mdi-account-hard-hat-outline</v-icon> {{ calcTime(mergeRequest['time'], true) }} </span>
                                </template>
                              </v-tooltip>

                              <v-icon class="ml-3">mdi-clock-outline</v-icon> {{ (mergeRequest['estimate'] === null) ? '-' : calcTime(mergeRequest['estimate'], true) }}
                            </div>
                          </div>
                          <div class="pt-4">
                            <div><v-icon class="ml-3 merge-request-icon" style="font-size:2.4em;color:#eee;">mdi-chevron-right</v-icon></div>
                          </div>

                        </div>
                      </div>
                      <div>

                      </div>
                    </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
          </v-col>
          <v-col col="6">
              <v-row v-if="projects['mergeRequests'] !== undefined && projects['mergeRequests'].length === 0">
                <v-col col="12" class="w-100  mb-8 mt-8">
                  <div class="text-center">
                    <v-icon style="font-size:4em;">mdi-gitlab</v-icon>
                  </div>
                  <div class="text-center" style="font-size:0.9em;">Aucune merge request enregistré pour le moment
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-6"
                     v-if="projects['mergeRequests'] !== undefined && projects['mergeRequests'].length > 0">
                <v-col cols="12" class="d-flex pl-6 mb-0 pb-0">
                  <v-icon style="font-size:1.8em;margin-top:8px;">mdi-gitlab</v-icon>
                  <div class="pt-2 pl-2" style="font-size:1.2em;font-weight:bold;">Gitlab</div>
                </v-col>
                <v-col col="12">
                  <div class="mb-0 mr-list" v-for="mergeRequest in projects['mergeRequests']">
                    <v-card class="pl-3 pr-3 fadeUp" style="min-height:93px;">
                    <div class="d-flex">
                      <div class="flex-grow-1 d-flex">
                        <div class="mr-3" style="min-width:40px;">
                          <div class="stat-card-icon text-center" style="margin:0 auto;"
                               :class="{'stat-card-success': mergeRequest['state'] === 'merged'}">
                            <v-icon :class="{'text-success': mergeRequest['state'] === 'merged'}">
                              {{ mergeRequest['state'] === 'merged' ? 'mdi-check' : 'mdi-clock' }}
                            </v-icon>
                          </div>
                          <div style="font-size:0.7em;color:#999;text-align:center;margin-top:2px;">
                            {{ calcTime(mergeRequest['diff']) }}
                          </div>
                        </div>
                        <div>
                          <div class="d-flex">
                            <div style="font-weight:bold;">{{ mergeRequest['name'] }}</div>
                            <div class="ml-2" style="margin-top:-5px;"><a :href="mergeRequest['url']" target="_blank">
                              <v-icon style="font-size:1.2em;margin-top:8px;">mdi-link-variant</v-icon>
                            </a></div>
                          </div>

                          <div style="color:#959595;font-size:0.9em;">{{ mergeRequest['repository']['name'] }}</div>
                          <div v-if="mergeRequest['youtrack']['issue'].length !== 0"><a
                              style="font-size:0.85em!important;" :href="mergeRequest['youtrack']['issue']['url']"
                              target="_blank">{{ mergeRequest['youtrack']['issue']['id'] }} -
                            {{ mergeRequest['youtrack']['issue']['title'] }}</a></div>
                        </div>
                      </div>
                      <div>
                        <div class="text-error d-flex" style="text-align:right;font-size:0.8em;"
                             v-for="error in mergeRequest['youtrack']['issue']['error']">
                          <div>
                            <v-icon style="font-size:1.8em;margin-top:8px;">mdi-alert</v-icon>
                          </div>
                          <div style="padding-top:10px;" class="pl-1"
                               v-if="error === 'BAD_STATUT' && error !== 'QUICK_MERGE'">{{
                              error === 'BAD_STATUT' ? 'Ticket non déplacé, status actuel : ' + mergeRequest['youtrack']['issue']['status'] : ''
                            }}
                          </div>
                          <div style="padding-top:10px;" class="pl-1" v-if="error === 'QUICK_MERGE'">
                            {{ error === 'QUICK_MERGE' ? 'Merge trop rapide' : '' }}
                          </div>
                        </div>
                        <div v-if="mergeRequest['youtrack']['issue'].length === 0" class="text-error d-flex"
                             style="text-align:right;font-size:0.8em;">
                          <div>
                            <v-icon style="font-size:1.8em;margin-top:8px;">mdi-alert</v-icon>
                          </div>
                          <div style="padding-top:10px;" class="pl-1">Pas de ticket</div>
                        </div>
                      </div>
                    </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from '@/services/DashboardService.js';
import timeago from 'vue-timeago3'
import { fr } from 'date-fns/locale';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import 'moment/locale/fr';
import UsersService from '@/services/UsersService.js';
import ProjectsService from '@/services/ProjectsService.js';
import Loader from '@/components/ui/Loader.vue';
import StatCard from '@/components/ui/StatCard.vue';
import ChartBar from '@/components/charts/chartBar.vue';

moment.locale('fr');

export default {
  name: 'ProjectsStat',
  computed: {
    fr() {
      return fr
    }
  },
  components: { ChartBar, StatCard, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      projects: [],
      projectsList: [],
      selectedProject: null,
      date: new Date(),
      moment: moment,
      chartMr: [],
      showIssue: false,
      detailIssue: null,
      youtrackStat: 1
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    totalPastTime() {
      let duration = this.projects['youtrack']['totalDuration'];
      let estimate = this.projects['estimatedProject'];

      if (duration > estimate) {
        return 100 - (((duration / estimate * 100) - 100) / 2);
      } else {
        return duration / estimate * 100;
      }
    },
    totalFreeTime() {
      let duration = this.projects['youtrack']['totalDuration'];
      let estimate = this.projects['estimatedProject'];

      if (duration > estimate) {
        return 0;
      } else {
        return 100 - (duration / estimate * 100);
      }
    },
    totalOvertime() {
      let duration = this.projects['youtrack']['totalDuration'];
      let estimate = this.projects['estimatedProject'];

      if (duration > estimate) {
        return ((duration / estimate * 100) - 100) / 2;
      } else {
        return 0;
      }
    },
    openIssue(issue) {
      this.showIssue = true;
      this.detailIssue = issue
    },
    selectYoutrackStat(item) {
      this.youtrackStat = item['id'];
      this.loading = true;
      this.loadProjects();
    },
    async loadProjects() {
      try {
        await ProjectsService.projectsStat(this.selectedProject['id'], this.youtrackStat).then(response => {
          this.projects = response['projects'];
          this.chartMr = response['projects']['charts']['mr'];
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async loadProjectsList(firstLoad = false) {
      try {
        await ProjectsService.projectsListForSelect().then(response => {
          this.projectsList = response['projects'];

          if (this.$route.params.id !== undefined) {
            this.projectsList.forEach(item => {
              if (item['id'] == this.$route.params.id) {
                this.selectedProject = item;
              }
            });
          } else {
            this.selectedProject = this.projectsList[0];
          }

          if (firstLoad) {
            this.loadProjects();
          }
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
      }
    },
    selectProject(project) {
      this.selectedProject = project;
      this.projects = [];
      this.loading = true;
      this.loadProjects();
      this.$router.push({ name: 'ProjectsStatSpec', params: { id: this.selectedProject['id'] } });
    },
    addBusinessDays(date, days) {
      let count = 0;
      while (count < days) {
        date = date.add(1, 'days');
        // Si la date n'est ni un samedi ni un dimanche, incrémente le compteur
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          count++;
        }
      }
      return date;
    },
    calcTime(time, full = false, dayInfo = false) {
      if(time > (7 * 60) && full) {
        let day = 7 * 60;
        let dayNumber = Math.floor(time / day);
        let hour = time - (dayNumber * 7 * 60);
        let hourNumber = 0;
        let minute = 0;

        if(hour >= 60) {
          hourNumber = Math.floor(hour / 60);
          minute = hour - (hourNumber * 60);
        } else {
          hourNumber = Math.floor(hour);
        }

        if(dayInfo) {
          let moment = this.moment();
          moment = this.addBusinessDays(moment, dayNumber);
          return dayNumber + ' Jours <small>(' + moment.format('DD/MM/YYYY') + ')</small>';
        } else {
          return dayNumber + ' jour' + ((dayNumber > 1) ? 's' : '')  + ' et ' + hourNumber + ' h ' + (minute < 10 ? '0' + minute : minute)
        }


        //eturn dayNumber + ' J/H et ' + hourNumber + 'h' + minute;
      }
      else if (time > 60) {
        let heure = Math.floor(time / 60);
        let min = time - (heure * 60);

        let out = '';
        if (heure > 0) {
          out = heure + ' h'

          let minRest = time - heure * 60;

          if(minRest > 0) {
            out = out + ' ' + minRest

            if(full) {
              out = out + ' minutes';
            }
          } else if(minRest === 0) {
            if(full) {
              out = out + ' 00 minutes';
            }
          }
        }

        if (min > 0 && heure === 0) {
          out = (out === '') ? min + ' min' : out + ' ' + Math.floor(min) + ' min'
        }

        return out;
      } else {
        return time + ' min'
      }
    }
  },
  mounted() {
    if (localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true
    this.loadProjectsList(true);
  }

}
</script>


<style scoped>
.stat-info-card {
  position: relative;
  z-index: 99;
  cursor: pointer;

  .info {
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    display: none;
    position: absolute;
    top: 50px;
    opacity: 0;
    z-index: 99;
    box-shadow: 1px 0 20px #00000014;
    color: #999;
    font-size: 0.85em;
  }

  &:hover {
    .info {
      display: block;
      opacity: 1;
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-fill-mode: both;
      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: both;
    }
  }
}

.merge-request-item {
  cursor:pointer;

  &:hover {
    box-shadow: 0px 0 10px #00000014;

    .merge-request-icon {
      color: #727070 !important;
    }
  }
}

.side-close {
  position:absolute;top:15px;right:140px;
  cursor:pointer;
  color:#999;

  &:hover {
    color:#575757;
  }
}
</style>
