import api from '@/services/ApiService'

class MonitoringService{
  async list() {
    const response = await api.get('/monitoring')
    return response.data
  }
  async homeOfficeAdminList() {
    const response = await api.get('/homeoffice/admin')
    return response.data
  }
  async homeOfficeRequestAdd(post) {
    const response = await api.post('/homeoffice', post)
    return response.data
  }
  async homeOfficeRequestDelete(id) {
    const response = await api.delete('/homeoffice/' + id)
    return response.data
  }
  async homeOfficeAdminApprove(id, post) {
    const response = await api.post('/homeoffice/admin/validate/' + id, post)
    return response.data
  }

}

export default new MonitoringService()
