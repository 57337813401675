<template>
  <v-container>
    <v-row class="d-flex pr-4 pt-6 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        Administration <v-icon>mdi-chevron-right</v-icon> Edition d'un projet
      </div>
      <div>
        <v-btn @click="createProject()" :disabled="!form" class="bg-primary btn">Sauvegarder</v-btn>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="d-block">
            <v-form v-model="form">
              <v-row>
                <v-col col="12">
                  <div class="form-group">
                    <div class="form-label">Nom du projet</div>
                    <div class="form-input">
                      <v-text-field v-model="formData.name" :rules="rules['name']"></v-text-field>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Estimation en J/H</div>
                    <div class="form-input">
                      <v-text-field v-model="formData.estimated"></v-text-field>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Nom du projet youtrack</div>
                    <div class="form-input">
                      <v-select
                          class="w-100 form-select"
                          :rules="rules['youtrackName']"
                          v-model="formData.youtrackName"
                          :items="youtrackProject"
                          item-value="id"
                          item-title="name"
                      ></v-select>

                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Namespace(s) kubernete</div>
                    <div class="form-input">
                      <v-select
                          class="w-100 form-select"
                          v-model="formData.namespaces"
                          :items="this.namespaces"
                          multiple
                      ></v-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Projet(s) Gitlab</div>
                    <div class="form-input">
                      <v-select
                          class="w-100 form-select"
                          v-model="formData.gitlabRepos"
                          item-text="title"
                          :return-object="true"
                          :items="gitlabRepos"
                          :item-props="true"
                          multiple
                      >
                      </v-select>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import ProjectsService from "@/services/ProjectsService.js";
moment.locale('fr');

export default {
  name: 'ProjectsEdit',
  components: { GuestCard, BackButton },
  data() {
    return {
      form: null,
      namespaces: [],
      gitlabRepos: [],
      youtrackProject: [],
      formData: {name : null, youtrackName: null, gitlabRepos : null, estimated: null},
      rules: {
        name : [
          value => {
            if (value) return true
            return 'Name is required.'
          },
        ],
        youtrackName : [
          value => {
            if (value) return true
            return 'Name is required.'
          },
        ],
      }
    }
  },
  beforeUnmount() {
  },
  methods: {
    itemProps (item) {
      return {
        title: item.name,
        subtitle: item.url,
      }
    },
    async createProject() {
      this.loading = true
      try {
        await ProjectsService.projectUpdate(this.$route.params.id, this.formData).then(response => {
          useSnackbarStore().openSnackbar({
            text: 'Mise à jour éffectuée',
            color: 'success'
          })
          this.$router.push({ name: 'Projects' });
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    ProjectsService.projectDetail(this.$route.params.id).then(response => {
      this.formData = response['project'];


      UsersService.namespaceList(response['project']['cluster']['id']).then(response => {
        this.namespaces = response['namespaces'];
      });
    });


    ProjectsService.gitlabRepoList(this.$route.params.id).then(response => {
      response['repos'].forEach(item => {
        this.gitlabRepos.push(item);
      })
    });

    ProjectsService.projectsYoutrack().then(response => {
      response['projects'].forEach(item => {
        this.youtrackProject.push(item);
      })
    });
  }

}
</script>


<style scoped>
</style>
