<template>
  <v-container>
    <v-row class="d-flex pr-4 pt-3 pb-1 pt-6">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        <v-icon>mdi-chevron-right</v-icon> Ajout d'un projet
      </div>
      <div>
        <v-btn @click="createProject()" :disabled="!form" class="bg-primary btn">Sauvegarder</v-btn>
      </div>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card>
          <div class="d-block pl-4 pr-4 pt-3">
            <v-form v-model="form">
              <v-row>
                <v-col col="12">

                  <v-row>
                    <v-col col="10">
                  <div class="form-group">
                    <div class="form-label">Nom du projet</div>
                    <div class="form-input">
                      <v-text-field v-model="nameValue" :rules="rules['name']"></v-text-field>
                    </div>
                  </div>
                    </v-col>
                    <v-col col="2">
                  <div class="form-group">
                    <div class="form-label">Estimation en J/H</div>
                    <div class="form-input">
                      <v-text-field v-model="formData.estimated"></v-text-field>
                    </div>
                  </div>
                    </v-col>
                  </v-row>
                  <div class="form-group">
                    <div class="form-label">Nom du projet youtrack</div>
                    <div class="form-input">
                        <v-select
                            class="w-100 form-select"
                            v-model="formData.youtrackName"
                            :items="youtrackProject"
                            item-value="id"
                            item-title="name"
                        ></v-select>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-label">Projet(s) Gitlabs</div>
                    <div class="form-input">
                      <v-autocomplete
                          class="w-100"
                          v-model="formData.gitlabRepos"
                          item-text="title"
                          :return-object="true"
                          :item-props="true"
                          :items="gitlabRepos"
                          multiple
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <div class="form-group">
            <div class="form-label">Cluster kubernetes</div>
            <div class="form-input">
              <v-select
                  class="w-100 form-select"
                  v-model="clustersValue"
                  :rules="rules['cluster']"
                  :items="this.clusters"
                  item-value="id"
                  item-title="name"
              ></v-select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label d-flex">
              <div class="flex-grow-1">Namespace(s) kubernete</div>
             </div>
            <div class="form-input" v-if="!formData.addNamespace">
              <v-select
                  class="w-100 form-select"
                  v-model="formData.namespaces"
                  :items="this.namespaces"
                  multiple
              ></v-select>
            </div>
            <div class="form-input" v-if="formData.addNamespace">
              <v-text-field v-model="formData.namespaces" :disabled="true"></v-text-field>
            </div>

            <div class="form-label d-flex">
              <div class="flex-grow-1">Environnement</div>
            </div>
            <div class="d-flex">
              <v-checkbox label="Dev" v-model="formData.dev"></v-checkbox>
              <v-checkbox label="Recette" v-model="formData.rec"></v-checkbox>
            </div>

            <div class="form-label d-flex">
              <div class="flex-grow-1">Modules préinstallés</div>
            </div>
            <div class="d-flex">
              <v-checkbox label="Mailhog" v-model="formData.mailhog"></v-checkbox>
              <v-checkbox label="Mysql" v-model="formData.mysql"></v-checkbox>
              <v-checkbox label="Postgresql"  v-model="formData.postgresql"></v-checkbox>
            </div>
          </div>

        </v-card>
      </v-col>


      <v-dialog max-width="500" v-model="dialog" persistent>
        <template v-slot:activator="{ props: activatorProps }">

        </template>

        <template v-slot:default="{ isActive }">
          <v-card title="Ajout réussie">
            <v-card-text>
              <div v-for="data in this.dialogData">{{ data['name'] }} ({{ data['env'] }}) : <a :href="'https://' + data['domain']" target="_blank">{{ data['domain'] }}</a></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text="Close Dialog"
                  @click="dialog = false;$router.push({ name: 'Projects' })"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import ProjectsService from "@/services/ProjectsService.js";
moment.locale('fr');

export default {
  name: 'ProjectsAdd',
  components: { GuestCard, BackButton },
  data() {
    return {
      form: null,
      clustersValue: 1,
      dialog : false,
      dialogData: [],
      nameValue: null,
      namespaces: [],
      clusters: [],
      gitlabRepos: [],
      youtrackProject: [],
      formData: {
        name : null, youtrackName: null, gitlabRepos : null, estimated: null, clusters: 1,
        mysql : false, mailhog: true, postgresql: false, dev : true, rec: true, addNamespace: true
      },
      rules: {
        name : [
          value => {
            if (value) return true
            return 'Le nom du projet est requis.'
          },
        ],
        cluster : [
          value => {
            if (value) return true
            return 'Le cluster est requis.'
          },
        ],
      }
    }
  },
  beforeUnmount() {
  },
  watch: {
    clustersValue: {
      handler(val){
        this.formData.clusters = val;
      },
      deep: true
    },
    nameValue: {
      handler(val){
        this.formData.name = this.nameValue;
        if(this.formData.addNamespace && this.formData.name  != null){

          if(this.formData.dev) {
            this.formData.namespaces = this.formData.name + '-dev';
          } if(this.formData.rec) {
            this.formData.namespaces =  this.formData.namespaces + ((this.formData.namespaces !== '') ? ',' + this.formData.name + '-rec' : this.formData.name + '-rec')
          }
        }
      },
      deep: true
    }
  },
  methods: {
    itemProps (item) {
      console.error(item);
      return {
        title: item.name,
        subtitle: item.url,
      }
    },
    resetNamespace() {
      this.formData.addNamespace=!this.formData.addNamespace;

      if(this.formData.addNamespace) {
        if(this.formData.name == null || this.formData.name === '' || this.formData.name === undefined) {
          this.formData.namespaces = null
        } else {
          this.formData.namespaces = this.formData.name + '-dev,' + this.formData.name + '-rec'
        }
      } else {
        this.formData.namespaces = null;
      }

    },
    async createProject() {
      this.loading = true
      try {
        this.formData.namespaces = this.formData.name;

        await ProjectsService.projectAdd(this.formData).then(response => {
          this.dialog = true;
          this.dialogData = response.services;
          console.log(this.dialogData)
          //useSnackbarStore().openSnackbar({
          //  text: 'Ajout du projet éffectuée',
          //  color: 'success'
          //})
          //this.$router.push({ name: 'Projects' });
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    UsersService.namespaceList(this.formData.clusters).then(response => {
      this.namespaces = response['namespaces'];
    });

    UsersService.clustersList().then(response => {
      this.clusters = response;
    });

    ProjectsService.gitlabRepoList().then(response => {
      response['repos'].forEach(item => {
        this.gitlabRepos.push(item);
      })
    });

    ProjectsService.projectsYoutrack().then(response => {
      response['projects'].forEach(item => {
        this.youtrackProject.push(item);
      })
    });
  }

}
</script>
