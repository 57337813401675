import api from '@/services/ApiService'

class DashboarsService{
  async loadDashboard(cluster) {
    const response = await api.get('/dashboard?cluster=' + cluster)
    return response.data
  }
  async clusterBar() {
    const response = await api.get('/cluster-bar')
    return response.data
  }
  async loadCluster(cluster) {
    const response = await api.get('/clusters?cluster=' + cluster)
    return response.data
  }
  async loadNamespace(cluster, name) {
    const response = await api.get('/namespace/' + name + '?cluster=' + cluster, {})
    return response.data
  }
  async loadLog(cluster, namespace, pod, cancelTokenSource, label = null) {
    try {
      let url = '/logs/' + namespace + '/' + pod + '?cluster=' + cluster;

      if(label !== null) {
        url += '&label=' + label;
      }

      const response = await api.get(url, {
        cancelToken: cancelTokenSource.token
      })
      return response.data
      } catch (error) {
        return [];
      }


  }
  async deletePods(cluster, namespace, pod) {
    const response = await api.get('/delete/' + namespace + '/' + pod + '?cluster=' + cluster, {})
    return response.data
  }
  async redirectPod(cluster, namespace, pod, port) {
    let url = '/mysqlredirect/' + cluster + '/' + namespace + '/' + pod;

    if(port !== null) {
      url = url + '?port=' + port;
    }

    const response = await api.get(url, {})
    return response.data
  }
  async mysql(cluster, namespace, pod, port, query) {
    const response = await api.get('/mysqlredirect/' + cluster + '/' + namespace + '/' + pod + '?query=' + query + '&port=' + port, {})
    return response.data
  }
}

export default new DashboarsService()
