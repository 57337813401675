<template>
  <v-container class="h-100 pt-4">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        Administration <v-icon>mdi-chevron-right</v-icon> Demande de télétravail
      </div>
      <div>
        <router-link :to="'/homeoffice/create'">

        </router-link>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card class="p-0">
          <div class="d-flex">
            <v-table class="w-100 custom-table">
              <thead class="text-left">
              <tr>
                <th>Date de début</th>
                <th>Date de fin</th>
                <th>Auteur</th>
                <th>Etat</th>
                <th width="50">Action</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="project in requests" >
                <td class="text-left d-flex">
                  <div v-if="project['validated'] === false" style="background:#b53351!important;max-width:5px;!important;margin-top:0px;margin-left:-16px;min-width:5px!important;display:inline-block;height:100%;"></div>
                  <div  v-if="project['validated'] === true" style="background:#30a76d!important;max-width:5px;!important;margin-top:0px;margin-left:-16px;min-width:5px!important;display:inline-block;height:100%;"></div>
                  <div  v-if="project['validated'] === null" style="background:#ccc!important;max-width:5px;!important;margin-top:0px;margin-left:-16px;min-width:5px!important;display:inline-block;height:100%;"></div>
                  <div class="d-flex justify-center align-content-center" style="justify-content: center;align-items: center;padding-left:10px;">{{ moment(project['date']['date']).format('DD/MM/YYYY') }}</div>
                </td>
                <td class="text-left">{{ moment(project['date']['date']).format('DD/MM/YYYY') }}</td>
                <td class="text-left">{{ project['user']['firstname'] }} {{ project['user']['lastname'] }}</td>
                <td class="text-left">
                  <div class="chips-success" v-if="project['validated'] === true"> <v-icon>mdi-check</v-icon>{{ formatStatus(project['validated']) }}</div>
                  <div class="chips-error" v-if="project['validated'] === false"> <v-icon>mdi-cancel</v-icon>{{ formatStatus(project['validated']) }}</div>
                  <div class="chips-wait" v-if="project['validated'] === null"> <v-icon>mdi-clock</v-icon>{{ formatStatus(project['validated']) }}</div>
                </td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="pt-3 pb-3 pr-3 pl-3 cursor-pointer">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </div>
                    </template>
                    <v-list class="custom-vlist">
                      <v-list-item class="pl-0 pr-0">
                        <v-list-item-title class="pt-3 pb-3 pr-6 pl-6 cursor-pointer list-item-title">Actions</v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="updateRequest(project['id'], true)"><div class="d-block flex-grow-1 pr-4">Valider</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                        <v-list-item-title class="pt-4 pb-4 pr-3 pl-6 cursor-pointer d-flex list-item-hover" @click="updateRequest(project['id'], false)"><div class="d-block flex-grow-1 pr-4">Refuser</div><v-icon class="icon">mdi-chevron-right</v-icon></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import JobService from "@/services/JobService.js";
import Loader from "@/components/ui/Loader.vue";
moment.locale('fr');

export default {
  name: 'HomeOfficeAdminListPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: {Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      requests: [],
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadHomeOffice() {
      this.loading = true
      try {
        JobService.homeOfficeAdminList().then(response => {
          this.requests = response['requests'];
          console.log(this.requests );
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    formatStatus(status) {
      if(status === null) {
        return 'En attente';
      } else if(status === true) {
        return 'Validé'
      } else if(status === false) {
        return 'Refusé'
      }
    },
    async updateRequest(id, status) {
      let message = (status) ? 'Etes vous sure de valider ?' : 'Etes vous sure de refuser ?';
      let check = confirm(message)

      if(check) {
        JobService.homeOfficeAdminApprove(id, {valid : status}).then(() => {
          useSnackbarStore().openSnackbar({
            text: 'Demande de télétravail validé avec succès',
            color: 'success'
          })
          this.loadHomeOffice();
        });
      }

    },
  },
  mounted() {
    this.loading = true;
    this.loadHomeOffice();
  }

}
</script>
