<script>
import { useSnackbarStore } from '@/stores/SnackbarStore.js'

export default {
  name: 'Snackbar',
  computed: {
    snackbar() {
      return useSnackbarStore();
    }
  }
}
</script>

<template>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :multi-line="snackbar.multiline"
      variant="tonal"
      location="bottom right"
      elevation="10"

    >
      <div class="snack-title">
        <div class="snack-icon">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </div>
        <div>{{ snackbar.title }}</div>
      </div>
      <div  class="snack-text" v-html="snackbar.text"></div>
    </v-snackbar>
</template>
